
<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed:{
      tmpTheme(){
          return {
              '--bgColor':this.$store.state.sysConfig.colorConfig.bgColor,
              '--fontColor':this.$store.state.sysConfig.colorConfig.fontColor
          };
      }
  },
  created(){
      const _this = this;
      _this.loadconfig(false,function(data){})
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function() {
         this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="scss">
@import url('./assets/font/iconfont.css');
*{
  margin: 0;
  padding: 0;
}
body {
    background-color: #f7f7f7;
    font-size: 14px;
}
li{
  list-style-type: none;
}
a{
  text-decoration: none;
}
.nothing {
  padding: 50px 0;
  text-align: center;
  color: #999;
}
.page {
  text-align: center;
  padding: 30px 0;
}
.el-pagination {
  font-weight: unset!important;
}
.el-carousel__arrow--right, .el-notification.right {
  right: 40%!important;
}
.el-select-dropdown__item.selected {
  color: #343434!important;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
  border-color: #636363!important;
}
.el-input__icon {
  line-height: 30px!important;
}
.el-select .el-input__inner:focus {
  border-color: #a1a1a1!important;
}
</style>
