
import Network from './network';
// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
export default {
    // 登录
    login:(data) => Network.post('api/user/login',data),
    // 登录
    mobileLogin:(data) => Network.post('api/user/mobileLogin',data),
    // 注册
    register:(data) => Network.post('api/user/Register',data),
    //验证公司、手机号
    checkphonecompany:(data) => Network.get('api/user/CheckPhoneOrCompany',data),
    // 找回密码
    modify:(data) => Network.post('api/user/modifyPwd',data),
    // 获取图文验证码
    getVierificationCode:() => Network.get('api/user/GetVierificationCode'),
    // 发送短信
    sendMobileCode:(data) => Network.post('api/user/SendRegisterCode',data),
    // 忘记密码
    forgetPwd:(data) => Network.post('api/user/ForgetPwd',data),
    // 修改密码
    updatePwd:(data) => Network.post('api/user/UpdatePwd',data),
    // 获取用户信息
    getCurrentUserInfo:(data) => Network.get('api/user/GetCurrentUserInfo',data),
    // 系统配置
    config:() => Network.get('api/config/GetSysConfig'),
    // 获取注册协议
    getSysConfigToRegister:() => Network.get('api/user/GetSysConfigToRegister'),
    // 轮播图
    chartlist:(data) => Network.get('api/SysContentConfig/GetSysCarouselChartList',data),
    // 新闻公告
    newslist:(data) => Network.get('api/SysContentConfig/GetNewsList',data),
    // 底部导航信息
    navigationlist:(data) => Network.get('api/SysContentConfig/GetBottomNavigationsList',data),
    // 头部导航分类
    classify:() => Network.get('api/MallSlassification/GetMallSlassificationList'),
    // 分类购买
    category:(data) => Network.get('api/MallSlassification/SearchMallSlassification',data),
    // 产品条件查询
    getCondition:(data) => Network.get('api/Prdt/GetPrdtBaseInfo',data),
    // 产品列表查询
    getProduct:(data) => Network.get('api/Prdt/GetPrdtList',data),
    // 热词搜索
    getSearchRecordList:(data) => Network.get('api/Prdt/GetSearchRecordList',data),
    // 加入购物车
    addCart:(data) => Network.post('api/Cart/AddCart',data),
    // 修改购物车数量
    updateCartQty:(data) => Network.post('api/Cart/UpdateCartQty',data),
    // 修改购物车选中项
    updateCartCheck:(data) => Network.post('api/Cart/UpdateCartCheck',data),
    // 修改购物车选中项
    delCart:(data) => Network.post('api/Cart/DelCart',data),
    // 获取购物车产品个数
    getCartNum:(data) => Network.get('api/Cart/GetCartNum',data),
    // 获取购物车产品集合
    getCartPrdtList:(data) => Network.get('api/Cart/GetCartPrdtList',data),
    // 获取结算页面产品集合
    getSettlePrdtList:(data) => Network.get('api/Cart/GetSettlePrdtList',data),
    // 添加到货通知
    addCollection:(data) => Network.post('api/Collections/AddCollection',data),
    // 删除到货通知
    delCollection:(data) => Network.post('api/Collections/DelCollection',data),
    // 修改到货通知
    updateCollection:(data) => Network.post('api/Collections/UpdateCollection',data),
    // 添加到货通知
    getCollectionList:(data) => Network.get('api/Collections/GetPrdtList',data),
    // 获取服务器时间
    getCurrentDateTime:(data) => Network.get('api/user/GetCurrentDateTime',data),
    // 获取行政区域数据
    getDistrictById:(data) => Network.get('api/BuyerAddress/GetDistrictById',data),
    // 获取收货地址数据
    getUserAddressList:(data) => Network.get('api/BuyerAddress/GetUserAddress',data),
    // 获取收货地址数据
    getUserAddressInfo:(data) => Network.get('api/BuyerAddress/GetUserAddressInfo',data),
    // 新增收货地址数据
    addBuyerAddress:(data) => Network.post('api/BuyerAddress/AddBuyerAddress',data),
    // 修改收货地址数据
    uptBuyerAddress:(data) => Network.post('api/BuyerAddress/UptBuyerAddress',data),
    // 删除收货地址数据
    delBuyerAddress:(data) => Network.post('api/BuyerAddress/DelBuyerAddress',data),
    // 设置默认收货地址
    setBuyerAddressDefault:(data) => Network.post('api/BuyerAddress/UptBuyerAddressDefault',data),
    // 提交订单
    submitOrder:(data) => Network.post('api/Order/SubmitOrder',data),
    // 订单列表
    getOrderList:(data) => Network.get('api/Order/GetBuyerOrders',data),
    // 订单明细
    getOrdersDetails:(data) => Network.get('api/Order/GetBuyerOrdersDetails',data),
    // 订单取消
    cancelOrder:(data) => Network.post('api/Order/CancelOrder',data),
    // 订单确认收货
    sureOrder:(data) => Network.post('api/Order/SureOrder',data),
    // 再来一单
    BuyAgain:(data) => Network.get('api/Order/BuyAgain',data),
    // 退货列表
    getReturnOrderList:(data) => Network.get('api/Order/GetBuyerReturnOrders',data),
    // 退货详情
    getReturnOrderDetail:(data) => Network.get('api/Order/GetReturnOrderDetail',data),
    // 申请退货
    applyReturnOrder:(data) => Network.post('api/Order/ApplyReturnOrder',data),
    // 获取退货原因
    getReturnReasonList:(data) => Network.get('api/Order/GetReturnReasonList',data),
    // 获取站内消息
    getMessageList:(data) => Network.get('api/Message/GetMessageList',data),
    // 设置为已读
    setRead:(data) => Network.post('api/Message/UpdateIsRead',data),
    // 删除站内消息
    delMessage:(data) => Network.post('api/Message/DelMessage',data),
    // 对账列表
    getAccountList:(data) => Network.get('api/Account/GetAccountList',data),
    // 对账明细
    getAccountInfo:(data) => Network.get('api/Account/GetAccountInfo',data),
    // 获取开票信息
    getInvoInfo:() => Network.get('api/user/GetInvoInfo'),
    // 更新开票信息
    updateInvo:(data) => Network.post('api/user/UpdateInvo',data),
    // 更新收票信息
    updateReceipt:(data) => Network.post('api/user/UpdateReceipt',data),
    // 刷新taoken
    replaceToken:(data) => Network.post('api/user/replaceToken',data),
    //判断背景色是否变更
    changeColor:(data) => Network.get('api/config/IfColorChange',data),
    // 获取广告位
    GetAdvertisement:(data) => Network.get('api/SysContentConfig/GetAdvertisement',data),
    
    //获取支付方式
    GetPaymentList:(data) => Network.post('api/Pay/GetPayInfo',data),
    //提交支付
    PayOrder:(data) => Network.post('api/Pay/ToOrder',data),
    //获取支付状态
    GetOrderPayStatus:(data) => Network.get('api/Pay/GetOrderPayStatus',data),
    
    //多个订单支付
    ToOrderList:(data) => Network.post('api/Pay/ToOrderList',data),
    //订单通知接口
    NotifyOrder:(data) => Network.get('api/Pay/NotifyOrder',data),

    //对账单支付
    ToPyaAccountInfo:(data) => Network.post('api/Pay/ToPyaAccountInfo',data),
    //对账单支付状态查询
    NotifyAccountInfo:(data) => Network.get('api/Pay/NotifyAccountInfo',data),
    
    //地址识别
    GetAddressSplit:(data) => Network.get('api/config/GetAddressSplit',data),
    //收藏
    BuyerCollect:(data) => Network.get('api/Prdt/BuyerCollect',data),
}