
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  //重定向操作
  {path:'/home',redirect:'/'},
  {path:'/',component: () => import('../views/Home.vue')},

  {path:'/category',component: () => import('../views/Category.vue')},
  {path:'/catlist',component: () => import('../views/Catlist.vue')},
  {path:'/news',component: () => import('../views/News.vue'),
    children:[
      // {path:'/',redirect:'/show'},
      // {path:'/show',component: () => import('../components/Show.vue')}
    ]
  },
  {path:'/cart',component: () => import('../views/Cart.vue')},
  {path:'/settle',component: () => import('../views/Settlement.vue')},
  {path:'/WechatPay',component: () => import('../views/WechatPay.vue')},
  {path:'/completion',component: () => import('../views/Completion.vue')},
  {path:'/paytype',component: () => import('../views/Paytype.vue')},
  {path:'/CompletionAccount',component: () => import('../views/CompletionAccount.vue')},
  {path:'/PaytypeForOrder',component: () => import('../views/PaytypeForOrder.vue')},
  {
    path:'/Mine',
    component: () => import('../views/Mine.vue'),
    children:[
      {path:'/',redirect:'/membercenter'},
      {path:'/membercenter',component: () => import('../views/mine/MemberCenter.vue')},
      {path:'/order',component: () => import('../views/mine/Order.vue')},
      {path:'/notice',component: () => import('../views/mine/Notice.vue')},
      {path:'/record',component: () => import('../views/mine/Record.vue')},
      {path:'/invoicing',component: () => import('../views/mine/Invoicing.vue')},
      {path:'/station',component: () => import('../views/mine/Station.vue')},
      {path:'/personal',component: () => import('../views/mine/Personal.vue')},
      {path:'/modify',component: () => import('../views/mine/Modify.vue')},
      {path:'/address',component: () => import('../views/mine/Address.vue')},
      {path:'/children',component: () => import('../views/mine/Children.vue')},
      {path:'/relation',component: () => import('../views/mine/Relation.vue')},
      {path:'/orderdetail',component: () => import('../views/mine/OrderDetail.vue')},
      {path:'/returndetail',component: () => import('../views/mine/ReturnDetail.vue')},
      {path:'/apply',component: () => import('../views/mine/Apply.vue')},
      {path:'/account',component: () => import('../views/mine/AccountList.vue')},
      {path:'/accountdetail',component: () => import('../views/mine/AccountDetail.vue')},
    ]
  },
  //   ]
  // },
  
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/modify',
    component: () => import('../components/logins/modify.vue')
  },
  {
    path: '/register',
    component: () => import('../components/logins/register.vue')
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
