
import Api from '../../api/index.js';
export default {
    install(Vue) {
        Vue.prototype.loadconfig = function (isLoad,callback){
            let sysConfigStr = localStorage.getItem('sysConfig');
            if(isLoad){
                Api.changeColor().then(res=>{
                    if(res.data.status){
                        if(res.data.data){
                            Api.config().then(data=>{
                                if(data.data.status){
                                    this.$store.commit('setConfig', data.data.data);
                                    localStorage.setItem('sysConfig',JSON.stringify(data.data.data));
                                    localStorage.setItem('title',data.data.data.baseConfig.webTitle)
                                    localStorage.setItem('lunbo',data.data.data.baseConfig.imgWebUrl)
                                    localStorage.setItem('upload',data.data.data.baseConfig.sp_Type_DefPic)
                                    localStorage.setItem('base',JSON.stringify(data.data.data.baseConfig))
                                    localStorage.setItem('tmpTheme', JSON.stringify(data.data.data.colorConfig));
                                    this.$store.commit('updateTheme', data.data.data.colorConfig);
                                    callback&&callback(data.data.data);
                                } 
                            })
                        }
                    } 
                })
            }
            
            if(sysConfigStr != undefined){
                let sysConfig = JSON.parse(sysConfigStr)
                this.$store.commit('setConfig', sysConfig);
                this.$store.commit('updateTheme', sysConfig.colorConfig);
                localStorage.setItem('tmpTheme', JSON.stringify(sysConfig.colorConfig));
                callback&&callback(sysConfig);
            }else{
                Api.config().then(res=>{
                    if(res.data.status){
                        this.$store.commit('setConfig', res.data.data);
                        localStorage.setItem('sysConfig',JSON.stringify(res.data.data));
                        localStorage.setItem('title',res.data.data.baseConfig.webTitle)
                        localStorage.setItem('lunbo',res.data.data.baseConfig.imgWebUrl)
                        localStorage.setItem('upload',res.data.data.baseConfig.sp_Type_DefPic)
                        localStorage.setItem('base',JSON.stringify(res.data.data.baseConfig))
                        localStorage.setItem('tmpTheme', JSON.stringify(res.data.data.colorConfig));
                        this.$store.commit('updateTheme', res.data.data.colorConfig);
                        callback&&callback(res.data.data);
                    }
                })
            }
        }
    }
}